<template>
    <div class="grid-container">
        <div class="grid-x grid-padding-x">
            <div class="cell small-12">
                <h1>View TFF Futures</h1>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell small-12">
                <table class="wide-table">
                    <thead>
                        <tr>
                          <th>Market_and_Exchange_Names</th>
                          <th>As_of_Date_In_Form_YYMMDD</th>
                          <th>Report_Date_as_MM_DD_YYYY</th>
                          <th>CFTC_Contract_Market_Code</th>
                          <th>CFTC_Market_Code</th>
                          <th>CFTC_Region_Code</th>
                          <th>CFTC_Commodity_Code</th>
                          <th>Open_Interest_All</th>
                          <th>Dealer_Positions_Long_All</th>
                          <th>Dealer_Positions_Short_All</th>
                          <th>Dealer_Positions_Spread_All</th>
                          <th>Asset_Mgr_Positions_Long_All</th>
                          <th>Asset_Mgr_Positions_Short_All</th>
                          <th>Asset_Mgr_Positions_Spread_All</th>
                          <th>Lev_Money_Positions_Long_All</th>
                          <th>Lev_Money_Positions_Short_All</th>
                          <th>Lev_Money_Positions_Spread_All</th>
                          <th>Other_Rept_Positions_Long_All</th>
                          <th>Other_Rept_Positions_Short_All</th>
                          <th>Other_Rept_Positions_Spread_All</th>
                          <th>Tot_Rept_Positions_Long_All</th>
                          <th>Tot_Rept_Positions_Short_All</th>
                          <th>NonRept_Positions_Long_All</th>
                          <th>NonRept_Positions_Short_All</th>
                          <th>Change_in_Open_Interest_All</th>
                          <th>Change_in_Dealer_Long_All</th>
                          <th>Change_in_Dealer_Short_All</th>
                          <th>Change_in_Dealer_Spread_All</th>
                          <th>Change_in_Asset_Mgr_Long_All</th>
                          <th>Change_in_Asset_Mgr_Short_All</th>
                          <th>Change_in_Asset_Mgr_Spread_All</th>
                          <th>Change_in_Lev_Money_Long_All</th>
                          <th>Change_in_Lev_Money_Short_All</th>
                          <th>Change_in_Lev_Money_Spread_All</th>
                          <th>Change_in_Other_Rept_Long_All</th>
                          <th>Change_in_Other_Rept_Short_All</th>
                          <th>Change_in_Other_Rept_Spread_All</th>
                          <th>Change_in_Tot_Rept_Long_All</th>
                          <th>Change_in_Tot_Rept_Short_All</th>
                          <th>Change_in_NonRept_Long_All</th>
                          <th>Change_in_NonRept_Short_All</th>
                          <th>Pct_of_Open_Interest_All</th>
                          <th>Pct_of_OI_Dealer_Long_All</th>
                          <th>Pct_of_OI_Dealer_Short_All</th>
                          <th>Pct_of_OI_Dealer_Spread_All</th>
                          <th>Pct_of_OI_Asset_Mgr_Long_All</th>
                          <th>Pct_of_OI_Asset_Mgr_Short_All</th>
                          <th>Pct_of_OI_Asset_Mgr_Spread_All</th>
                          <th>Pct_of_OI_Lev_Money_Long_All</th>
                          <th>Pct_of_OI_Lev_Money_Short_All</th>
                          <th>Pct_of_OI_Lev_Money_Spread_All</th>
                          <th>Pct_of_OI_Other_Rept_Long_All</th>
                          <th>Pct_of_OI_Other_Rept_Short_All</th>
                          <th>Pct_of_OI_Other_Rept_Spread_All</th>
                          <th>Pct_of_OI_Tot_Rept_Long_All</th>
                          <th>Pct_of_OI_Tot_Rept_Short_All</th>
                          <th>Pct_of_OI_NonRept_Long_All</th>
                          <th>Pct_of_OI_NonRept_Short_All</th>
                          <th>Traders_Tot_All</th>
                          <th>Traders_Dealer_Long_All</th>
                          <th>Traders_Dealer_Short_All</th>
                          <th>Traders_Dealer_Spread_All</th>
                          <th>Traders_Asset_Mgr_Long_All</th>
                          <th>Traders_Asset_Mgr_Short_All</th>
                          <th>Traders_Asset_Mgr_Spread_All</th>
                          <th>Traders_Lev_Money_Long_All</th>
                          <th>Traders_Lev_Money_Short_All</th>
                          <th>Traders_Lev_Money_Spread_All</th>
                          <th>Traders_Other_Rept_Long_All</th>
                          <th>Traders_Other_Rept_Short_All</th>
                          <th>Traders_Other_Rept_Spread_All</th>
                          <th>Traders_Tot_Rept_Long_All</th>
                          <th>Traders_Tot_Rept_Short_All</th>
                          <th>Conc_Gross_LE_4_TDR_Long_All</th>
                          <th>Conc_Gross_LE_4_TDR_Short_All</th>
                          <th>Conc_Gross_LE_8_TDR_Long_All</th>
                          <th>Conc_Gross_LE_8_TDR_Short_All</th>
                          <th>Conc_Net_LE_4_TDR_Long_All</th>
                          <th>Conc_Net_LE_4_TDR_Short_All</th>
                          <th>Conc_Net_LE_8_TDR_Long_All</th>
                          <th>Conc_Net_LE_8_TDR_Short_All</th>
                          <th>Contract_Units</th>
                          <th>CFTC_SubGroup_Code</th>
                          <th>FutOnly_or_Combined</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(future, index) in futures" v-bind:key="index">
                            <td>{{ future.name }}</td>
                            <td>{{ future.classification }}</td>
                            <td>{{ future.date }}</td>
                            <td>{{ future.a }}</td>
                            <td>{{ future.b }}</td>
                            <td>{{ future.c }}</td>
                            <td>{{ future.d }}</td>
                            <td>{{ future.e }}</td>
                            <td>{{ future.f }}</td>
                            <td>{{ future.g }}</td>
                            <td>{{ future.h }}</td>
                            <td>{{ future.i }}</td>
                            <td>{{ future.j }}</td>
                            <td>{{ future.k }}</td>
                            <td>{{ future.l }}</td>
                            <td>{{ future.m }}</td>
                            <td>{{ future.n }}</td>
                            <td>{{ future.o }}</td>
                            <td>{{ future.p }}</td>
                            <td>{{ future.q }}</td>
                            <td>{{ future.r }}</td>
                            <td>{{ future.s }}</td>
                            <td>{{ future.t }}</td>
                            <td>{{ future.u }}</td>
                            <td>{{ future.v }}</td>
                            <td>{{ future.w }}</td>
                            <td>{{ future.x }}</td>
                            <td>{{ future.y }}</td>
                            <td>{{ future.z }}</td>
                            <td>{{ future.aa }}</td>
                            <td>{{ future.ab }}</td>
                            <td>{{ future.ac }}</td>
                            <td>{{ future.ad }}</td>
                            <td>{{ future.ae }}</td>
                            <td>{{ future.af }}</td>
                            <td>{{ future.ag }}</td>
                            <td>{{ future.ah }}</td>
                            <td>{{ future.ai }}</td>
                            <td>{{ future.aj }}</td>
                            <td>{{ future.ak }}</td>
                            <td>{{ future.al }}</td>
                            <td>{{ future.am }}</td>
                            <td>{{ future.an }}</td>
                            <td>{{ future.ao }}</td>
                            <td>{{ future.ap }}</td>
                            <td>{{ future.aq }}</td>
                            <td>{{ future.ar }}</td>
                            <td>{{ future.ass }}</td>
                            <td>{{ future.at }}</td>
                            <td>{{ future.au }}</td>
                            <td>{{ future.av }}</td>
                            <td>{{ future.aw }}</td>
                            <td>{{ future.ax }}</td>
                            <td>{{ future.ay }}</td>
                            <td>{{ future.az }}</td>
                            <td>{{ future.ba }}</td>
                            <td>{{ future.bb }}</td>
                            <td>{{ future.bc }}</td>
                            <td>{{ future.bd }}</td>
                            <td>{{ future.be }}</td>
                            <td>{{ future.bf }}</td>
                            <td>{{ future.bg }}</td>
                            <td>{{ future.bh }}</td>
                            <td>{{ future.bi }}</td>
                            <td>{{ future.bj }}</td>
                            <td>{{ future.bk }}</td>
                            <td>{{ future.bl }}</td>
                            <td>{{ future.bm }}</td>
                            <td>{{ future.bn }}</td>
                            <td>{{ future.bo }}</td>
                            <td>{{ future.bp }}</td>
                            <td>{{ future.bq }}</td>
                            <td>{{ future.br }}</td>
                            <td>{{ future.bs }}</td>
                            <td>{{ future.bt }}</td>
                            <td>{{ future.bu }}</td>
                            <td>{{ future.bv }}</td>
                            <td>{{ future.bw }}</td>
                            <td>{{ future.bx }}</td>
                            <td>{{ future.byy }}</td>
                            <td>{{ future.bz }}</td>
                            <td>{{ future.ca }}</td>
                            <td>{{ future.cb }}</td>
                            <td>{{ future.cc }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ViewTffFutures',
  data() {
    return {
      futures: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    getFutures() {
      axios.get(`/tffs/getFutures.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.futures = response.data.futures;
        });
    },
  },
  mounted() {
    this.getFutures();
  },
};
</script>
